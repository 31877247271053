<template>
  <v-row>
    <v-dialog
      v-model="show_dialog_template"
      scrollable
      persistent
      max-width="700"
    >
      <v-card>
        <v-card-title>
          <v-icon :color="color.theme" dense dark size="35" class="pr-3"
            >mdi-note-text</v-icon
          >
          {{
            type_manage === "V"
              ? $t("smartinput.view_template")
              : type_manage === "SC"
              ? $t("smartinput.create_template")
              : $t("smartinput.edit_template")
          }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row v-if="ui_mockup">
            <!-- NAME TEMPLATE FIELD -->
            <v-flex
              xl2
              lg2
              md2
              sm4
              xs4
              pa-2
              pb-n2
              mt-2
              style="display: flex;  align-items: center;"
            >
              <p class="font-weight-bold" :style="style_font_label">
                ชื่อ Template
              </p>
            </v-flex>
            <v-flex xl6 lg6 md6 sm8 xs8 mt-2 pb-n2>
              <v-text-field
                outlined
                solo
                flat
                dense
                v-model.trim="create_name_template"
                @blur="$v.create_name_template.$touch()"
                :error-messages="template_name_errormsg"
                color="#174966"
                class="pt-2"
              >
              </v-text-field>
            </v-flex>
            <v-flex
              md4
              class="mt-n3"
              v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)"
              style="display: flex; justify-content: end; align-items: center;"
            >
              <v-btn color="success" @click="fn_increase_field_template()">
                เพิ่ม Field
              </v-btn>
            </v-flex>
            <!-- FIELD TEMPLATE  -->
            <v-expansion-panels
              focusable
              id="data-expands"
              class="elevation-1"
              flat
              hover
              accordion
              v-model="panels_open"
            >
              <v-expansion-panel
                v-for="(item_field, index_field) in array_form_template"
                :key="index_field"
                class="data-expands"
              >
                <v-expansion-panel-header>
                  Field {{ index_field + 1 }}
                  <div
                    v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)"
                    :style="style_required"
                  >
                    <span>Required</span>
                    <v-checkbox
                      hide-details
                      class="mt-n1 ml-3"
                      @click.native.stop
                      v-model="item_field.template_field_required"
                    />
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout row class="pb-3">
                    <!-- ROW (KEY AND NAME FIELD) -->
                    <v-flex
                      xs12
                      v-if="['xs', 'sm'].includes($vuetify.breakpoint.name)"
                      style="display: flex; align-items: center; justify-content: end;"
                    >
                      <div :style="style_required_mobile" class="mt-2">
                        Required
                        <v-checkbox
                          hide-details
                          class="mt-n1 ml-3"
                          v-model="item_field.template_field_required"
                        />
                      </div>
                    </v-flex>
                    <v-flex
                      xl2
                      lg2
                      md2
                      sm3
                      xs4
                      pa-2
                      pb-n2
                      mt-2
                      :style="style_align_center"
                    >
                      <v-chip small class="mt-n4 px-2"
                        ><span class="font-weight-bold">Field Key</span></v-chip
                      >
                    </v-flex>
                    <v-flex xl3 lg4 md4 sm9 xs8 mt-2>
                      <v-text-field
                        outlined
                        solo
                        flat
                        dense
                        color="#174966"
                        class="pt-2"
                        v-model="item_field.template_field_key"
                        @blur="
                          $v.array_form_template.$each[
                            index_field
                          ].template_field_key.$touch()
                        "
                        :error-messages="
                          template_key_field_errormsg(index_field)
                        "
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex
                      xl2
                      lg2
                      md2
                      sm3
                      xs4
                      pa-2
                      pb-n2
                      mt-2
                      :style="
                        !['xs'].includes
                          ? style_justify_align_center
                          : style_align_center
                      "
                    >
                      <v-chip small class="mt-n4 px-2"
                        ><span class="font-weight-bold"
                          >Field Name</span
                        ></v-chip
                      >
                    </v-flex>
                    <v-flex xl5 lg4 md4 sm9 xs8 mt-2>
                      <v-text-field
                        outlined
                        solo
                        flat
                        dense
                        color="#174966"
                        class="pt-2"
                        v-model="item_field.template_field_name"
                        @blur="
                          $v.array_form_template.$each[
                            index_field
                          ].template_field_name.$touch()
                        "
                        :error-messages="
                          template_name_field_errormsg(index_field)
                        "
                      >
                      </v-text-field>
                    </v-flex>
                    <!-- //////////// -->
                    <!-- ROW TPYE FIELD -->
                    <v-flex xl2 lg2 md2 sm3 xs4 pa-2 pb-n2 mt-2>
                      <v-chip small class="mt-n4 px-2"
                        ><span class="font-weight-bold"
                          >Type Field</span
                        ></v-chip
                      >
                    </v-flex>
                    <v-flex xl10 lg10 md10 sm9 xs8>
                      <v-select
                        :items="items_type_template"
                        v-model="item_field.template_field_type"
                        outlined
                        dense
                        @change="fn_changetypefield(item_field, type_manage)"
                        :return-object="true"
                        item-value="items_value"
                      >
                        <template v-slot:item="{ item }">
                          <span>
                            {{ item.items_text }}
                          </span>
                        </template>
                        <template v-slot:selection="{ item }">
                          <span>
                            {{ item.items_text }}
                          </span>
                        </template>
                      </v-select>
                    </v-flex>
                    <!-- ////// -->
                    <!-- ROW DROPDOWN (IF SELECTED) -->
                    <div
                      style="width: 100%;"
                      v-if="
                        item_field.template_field_type.items_value === 'select'
                      "
                    >
                      <v-layout
                        v-if="!['xs'].includes($vuetify.breakpoint.name)"
                        v-for="(item_dropdown,
                        index_dropdown) in item_field.template_field_dropdown"
                        :key="index_dropdown"
                        row
                        class="px-6"
                        justify-center
                      >
                        <v-flex
                          xl3
                          lg3
                          md3
                          sm3
                          xs4
                          pb-n2
                          :style="style_justify_align_center"
                        >
                          <v-chip small class="mt-n4 px-2"
                            ><span class="font-weight-bold">{{
                              ["xs"].includes($vuetify.breakpoint.name)
                                ? "Value"
                                : "Dropdown Value"
                            }}</span></v-chip
                          >
                        </v-flex>
                        <v-flex xl4 lg4 md4 sm4 xs7>
                          <v-text-field
                            outlined
                            solo
                            flat
                            dense
                            color="#174966"
                            class="pt-2 ml-n1"
                            v-model.trim="item_dropdown.dropdown_value"
                            :error-messages="
                              template_dropdown_field_errormsg(
                                index_field,
                                index_dropdown
                              )
                            "
                            @blur="
                              $v.array_form_template.$each[
                                index_field
                              ].template_field_dropdown.$each[
                                index_dropdown
                              ].dropdown_value.$touch()
                            "
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex
                          xl3
                          lg2
                          md2
                          sm2
                          xs1
                          class="pl-xs-1 pl-2"
                          style="display: flex; justify-content: start;"
                        >
                          <v-btn
                            v-if="
                              index_dropdown ===
                                item_field.template_field_dropdown.length - 1
                            "
                            fab
                            x-small
                            color="mt-3"
                            depressed
                            @click="
                              fn_increase_dropdown(index_field, index_dropdown)
                            "
                          >
                            <v-icon>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                          <v-btn
                            v-if="index_dropdown + 1 > 2"
                            fab
                            x-small
                            color="error"
                            class="mt-3 ml-3"
                            depressed
                            @click="
                              fn_decrease_dropdown(index_field, index_dropdown)
                            "
                          >
                            <v-icon>
                              mdi-trash-can
                            </v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                      <!-- MOBILE OR SOME MODELS TABLET -->
                      <v-layout v-else>
                        <v-flex xs3 :style="style_justify_align_center">
                          <v-chip small class="mt-n4 px-2"
                            ><span class="font-weight-bold">Value</span></v-chip
                          >
                        </v-flex>
                        <v-flex xs6>
                          <v-text-field
                            outlined
                            solo
                            flat
                            dense
                            color="#174966"
                            class="pt-2"
                            v-model.trim="item_dropdown.dropdown_value"
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex
                          xs3
                          pt-n3
                          class="pl-3"
                          :style="style_align_center"
                        >
                          <v-btn
                            v-if="
                              index_dropdown ===
                                item_field.template_field_dropdown.length - 1
                            "
                            small
                            icon
                            class="mt-n5"
                            @click="
                              fn_increase_dropdown(index_field, index_dropdown)
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            color="error"
                            v-if="index_dropdown + 1 > 2"
                            small
                            icon
                            class="mt-n5"
                            @click="
                              fn_decrease_dropdown(index_field, index_dropdown)
                            "
                          >
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </div>
                    <v-flex xl12>
                      <v-btn
                        v-if="
                          index_field !== 0 || array_form_template.length > 1
                        "
                        block
                        color="error"
                        @click="fn_remove_template_field(index_field)"
                      >
                        Delete Field
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-flex
              xs12
              v-if="['xs'].includes($vuetify.breakpoint.name)"
              class="py-3"
              style="display: flex; justify-content: center;"
            >
              <v-btn small fab depressed @click="fn_increase_field_template()">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout row v-else>
            <!-- NAME TEMPLATE FIELD -->
            <!-- <v-flex
              xl2
              lg2
              md2
              sm4
              xs4
              pa-2
              pb-n2
              mt-2
              style="display: flex;  align-items: center;"
            >
              <p class="font-weight-bold" :style="style_font_label">
                {{
                  $t("default") === "en"
                    ? $t("smartinput.template")
                    : $t("smartinput.header_table.template_name")
                }}
              </p>
            </v-flex>
            <v-flex xl6 lg6 md6 sm8 xs8 mt-2 pb-n2>
              <v-text-field
                outlined
                solo
                flat
                dense
                height="37px"
                :hint="$t('smartinput.message_suggession')"
                v-model.trim="create_name_template"
                @blur="fn_detect_focus()"
                :disabled="isLoading || type_manage === 'V'"
                :error-messages="template_name_errormsg"
                color="#174966"
                class="pt-2"
              >
              </v-text-field>
            </v-flex>
            <v-flex lg6 xl6 md6 class="mt-n1 text-left">
              <v-checkbox
                class="mt-0 "
                v-model="checktemplatefolder"
                :label="$t('smartinput.selectfoldertemplate')"
              ></v-checkbox>
            </v-flex>
            <v-flex
              v-if="checktemplatefolder === true"
              lg6
              xl6
              md6
              class="mt-n1 text-right"
            >
              <v-btn
                small
                @click="openselectmainfoldertemplate = true"
                color="success"
                elevation="2"
                rounded
              >
                <v-icon>mdi-plus</v-icon>{{ $t("smartinput.select") }}
              </v-btn>
            </v-flex>
            <v-flex v-if="checktemplatefolder === true" lg12 xs12 class="mb-3">
              <v-card-actions class="mx-n3 mt-n4">
                <div
                  class="pa-1 px-3 text-left"
                  :style="
                    $vuetify.theme.dark
                      ? 'background-color:#474747'
                      : 'background-color:#f5f5f5'
                  "
                  style="width: 100%; border-radius: 10px;"
                >
                  <v-skeleton-loader
                    v-if="
                      isloading_expands === true &&
                        checktemplatefolder === true
                    "
                    :style="
                      $vuetify.theme.dark
                        ? 'background-color:#474747'
                        : 'background-color:#f5f5f5'
                    "
                    type="text"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    v-if="isloading_expands === true"
                    :style="
                      $vuetify.theme.dark
                        ? 'background-color:#474747'
                        : 'background-color:#f5f5f5'
                    "
                    type="text"
                  ></v-skeleton-loader>
                  <span
                    v-else
                    class="font-weight-bold"
                    style="font-size: 14px;"
                  >
                    <p v-if="checktemplatefolder === true" class="mb-0">
                      {{ $t("smartinput.foldertemplate") }}
                      {{
                        datafoldertemplate.path === undefined
                          ? $t("smartinput.notyetchosen")
                          : datafoldertemplate.folder_name
                      }}
                    </p>
                  </span>
                </div>
              </v-card-actions>
            </v-flex> -->
            
              <v-flex
              xl2
              lg2
              md2
              sm4
              xs4
              pa-2
              pb-n2
              mt-2
              style="display: flex;  align-items: center;"
              >
                  <p class="font-weight-bold" :style="style_font_label">
                  {{
                      $t("default") === "en"
                      ? $t("smartinput.template")
                      : $t("smartinput.header_table.template_name")
                  }}
                  </p>
              </v-flex>
              <v-flex xl6 lg6 md6 sm8 xs8 mt-2 pb-n2>
                  <v-text-field
                  outlined
                  solo
                  flat
                  dense
                  height="37px"
                  :hint="$t('smartinput.message_suggession')"
                  v-model.trim="create_name_template"
                  @blur="fn_detect_focus()"
                  :disabled="isLoading || type_manage === 'V'"
                  :error-messages="template_name_errormsg"
                  color="#174966"
                  class="pt-2"
                  >
                  </v-text-field>
              </v-flex>
              <v-flex lg6 xl6 md6 class="mt-n1 text-left">
            <v-checkbox
              :disabled="isloading_expands"
              class="mt-0 "
              v-model="checktemplatefolder"
              :label="$t('smartinput.selectfoldertemplate')"
            ></v-checkbox>
              </v-flex>
              <v-flex
                  lg6
                  xl6
                  md6
                  class="pb-2 text-right"
              >
                  <v-btn
                  v-if="checktemplatefolder === true"
                  small
                  class="mb-3"
                  @click="openselectmainfoldertemplate = true"
                  color="success"
                  elevation="2"
                  rounded
                  >
                  <v-icon>mdi-plus</v-icon>{{ $t("smartinput.select") }}
                  </v-btn>
              </v-flex>
              <v-flex lg12 xs12 class="mb-3">
                  <v-card-actions class="mx-n3 mt-n4" v-if="checktemplatefolder === true">
                  <div
                      class="pa-1 px-3 text-left"
                      :style="
                      $vuetify.theme.dark
                          ? 'background-color:#474747'
                          : 'background-color:#f5f5f5'
                      "
                      style="width: 100%; border-radius: 10px;"
                  >
                      <v-skeleton-loader
                      v-if="
                          isloading_expands === true &&
                          checktemplatefolder === true
                      "
                      :style="
                          $vuetify.theme.dark
                          ? 'background-color:#474747'
                          : 'background-color:#f5f5f5'
                      "
                      type="text"
                      ></v-skeleton-loader>
                      <v-skeleton-loader
                      v-if="isloading_expands === true"
                      :style="
                          $vuetify.theme.dark
                          ? 'background-color:#474747'
                          : 'background-color:#f5f5f5'
                      "
                      type="text"
                      ></v-skeleton-loader>
                      <span
                      v-else
                      class="font-weight-bold"
                      style="font-size: 14px;"
                      >
                      <p v-if="checktemplatefolder === true" class="mb-0">
                          {{ $t("smartinput.foldertemplate") }}
                          {{
                          datafoldertemplate.path === undefined
                              ? $t("smartinput.notyetchosen")
                              : datafoldertemplate.folder_name
                          }}
                      </p>
                      </span>
                  </div>
                  </v-card-actions>
              </v-flex>
            <!-- ปุ่มเพิ่มหัวข้อ (เก่า) -->
            <!-- <v-flex
              md4
              class="mt-n3"
              v-if="
                !['xs', 'sm'].includes($vuetify.breakpoint.name) &&
                  type_manage !== 'V'
              "
              style="display: flex; justify-content: end; align-items: center;"
            >
              <v-btn
                :disabled="isLoading"
                color="success"
                @click="fn_increase_field_template()"
              >
                {{ $t("smartinput.btn_add_field") }}
              </v-btn>
            </v-flex> -->
            <v-flex md12 v-if="isloading_expands">
              <v-skeleton-loader
                v-bind="attrs"
                type="card-heading, list-item-two-line"
              ></v-skeleton-loader>
              <v-skeleton-loader
                v-bind="attrs"
                type="list-item"
              ></v-skeleton-loader>
            </v-flex>
            <!-- FIELD TEMPLATE  -->
            <v-expansion-panels
              v-if="!isloading_expands"
              focusable
              :disabled="isLoading"
              class="elevation-1"
              hover
              accordion
              v-model="panels_open"
            >
              <v-expansion-panel
                v-for="(item_field, index_field) in array_form_template"
                :key="index_field"
              >
                <v-expansion-panel-header :style="{ '--right-position': right_position(index_field) }" @click="type_manage === 'ED' ? index_field === array_form_template.length - 1 ?  fn_scrollToBottom() : null : null">
                  <span class="font-weight-bold">
                    {{
                      item_field.template_field_key === ""
                        ? `${
                            $t("default") === "en" ? "Field" : "หัวข้อที่"
                          } ${index_field + 1}`
                        : item_field.template_field_key | subStr
                    }}
                  </span>
                  <!-- <div
                    v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)"
                    :style="style_required(index_field)"
                  >
                    <span class="font-weight-bold">{{
                      item_field.template_field_active
                        ? $t("smartinput.field_active")
                        : $t("smartinput.field_non_active")
                    }}</span>
                    <v-checkbox
                      v-if="type_manage !== 'V'"
                      hide-details
                      class="mt-n1 ml-3"
                      :disabled="fn_disabled_checkbox(index_field)"
                      @click.native.stop
                      v-model="item_field.template_field_active"
                    />
                  </div> -->
                  <div :style="style_remove" v-if="index_field !== 0 || array_form_template.length > 1">
                    <v-btn fab depressed icon small
                    @click.native.stop @click="fn_remove_template_field(index_field)">
                      <v-icon color="error">
                        mdi-trash-can
                      </v-icon>
                    </v-btn>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout row class="pb-3">
                    <!-- ROW (KEY AND NAME FIELD) -->
                    <!-- <v-flex
                    xs12
                    v-if="['xs', 'sm'].includes($vuetify.breakpoint.name)"
                    style="display: flex; align-items: center; justify-content: end;"
                  >
                    <div :style="style_required_mobile" class="mt-2">
                      <v-chip
                        small
                        :color="
                          item_field.template_field_active
                            ? 'success'
                            : 'error'
                        "
                        v-if="type_manage === 'V'"
                        ><span class="font-weight-bold">{{
                          item_field.template_field_active
                            ? $t("smartinput.field_active")
                            : $t("smartinput.field_non_active")
                        }}</span></v-chip
                      >
                      <span v-else class="font-weight-bold">{{
                        item_field.template_field_active
                          ? $t("smartinput.field_active")
                          : $t("smartinput.field_non_active")
                      }}</span>
                      <v-checkbox
                        v-if="type_manage !== 'V'"
                        hide-details
                        class="mt-n1 ml-3"
                        :disabled="fn_disabled_checkbox(index_field)"
                        v-model="item_field.template_field_active"
                      />
                      </div>
                    </v-flex> -->
                    <v-flex xl2 lg2 md2 sm3 xs4  pb-n2 mt-2 pt-5>
                      <v-chip small class="mt-n4 px-4"
                        ><span class="font-weight-bold">{{
                          $t("smartinput.template_form.field_key")
                        }}</span></v-chip
                      >
                    </v-flex>
                    <v-flex xl4 lg4 md4 sm9 xs8 mt-2>
                      <v-text-field
                        :disabled="type_manage === 'V'"
                        outlined
                        solo
                        flat
                        dense
                        color="#174966"
                        class="pt-2"
                        v-model="item_field.template_field_key"
                        @blur="fn_detect_focus_fieldKey(index_field)"
                        :error-messages="
                          template_key_field_errormsg(index_field)
                        "
                        @change="fn_change_field_key($event)"
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xl2 lg2 md2 sm3 xs4 pb-n2 mt-2 pt-5 :class="['xs', 'sm'].includes($vuetify.breakpoint.name) ? '' : 'pl-4'">
                      <v-chip small class="mt-n4 px-4" 
                        ><span class="font-weight-bold">{{ $t('default') === 'en' ? 'Symbol' : 'สัญลักษณ์' }}</span></v-chip
                      >
                    </v-flex>
                    <v-flex xl4 lg4 md4 sm9 xs8 mt-2 :class="['xs', 'sm'].includes($vuetify.breakpoint.name) ? '' : 'pl-4'">
                      <v-text-field
                        :disabled="type_manage === 'V'"
                        outlined
                        solo
                        flat
                        @keypress="fn_keypresssSymbol($event, index_field)"
                        dense
                        color="#174966"
                        class="pt-2"
                        v-model="item_field.template_field_symbol"
                        @blur="
                          $v.array_form_template.$each[
                            index_field
                          ].template_field_symbol.$touch()
                        "
                        :error-messages="
                          template_symbol_field_errormsg(index_field)
                        "
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xl2 lg2 md2 sm3 xs4  pb-n2 mt-2 pt-5>
                      <v-chip small class="mt-n4"
                        ><span class="font-weight-bold">{{
                          $t("smartinput.template_form.field_name")
                        }}</span></v-chip
                      >
                    </v-flex>
                    <v-flex xl9 lg9 md9 sm8 xs7 mt-2>
                      <v-text-field
                        :disabled="type_manage === 'V'"
                        outlined
                        solo
                        flat
                        dense
                        color="#174966"
                        class="pt-2"
                        v-model="item_field.template_field_name"
                        @blur="
                          $v.array_form_template.$each[
                            index_field
                          ].template_field_name.$touch()
                        "
                        :error-messages="
                          template_name_field_errormsg(index_field)
                        "
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex xl1 lg1 md1 sm1 xs1 pl-3 pb-n2 mt-2 pt-3>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" style="margin-right: 2px;">mdi-information</v-icon>
                        </template>
                        <span>{{$t("smartinput.field_name_detail")}}</span>
                      </v-tooltip>
                    </v-flex>
                    <!-- //////////// -->
                    <!-- ROW TPYE FIELD -->
                    <!-- <v-flex xl2 lg2 md2 sm3 xs4 pa-2 pb-n2 mt-2>
                      <v-chip small class="mt-n4 px-2"
                        ><span class="font-weight-bold">{{
                          $t("smartinput.template_form.field_type")
                        }}</span></v-chip
                      >
                    </v-flex>
                    <v-flex xl10 lg10 md10 sm9 xs8>
                      <v-select
                        :disabled="type_manage === 'V'"
                        :items="items_type_template"
                        v-model="item_field.template_field_type"
                        outlined
                        dense
                        @change="
                          fn_changetypefield(item_field, type_manage, $event)
                        "
                        :return-object="true"
                        item-value="items_value"
                      >
                        <template v-slot:item="{ item }">
                          <span>
                            {{ $t(item.items_text) }}
                          </span>
                        </template>
                        <template v-slot:selection="{ item }">
                          <span>
                            {{ $t(item.items_text) }}
                          </span>
                        </template>
                      </v-select>
                    </v-flex> -->
                    <!-- ////// -->
                    <!-- ROW DROPDOWN (IF SELECTED) -->
                    <!-- <div
                      style="width: 100%;"
                      v-if="
                        item_field.template_field_type.items_value ===
                          'DROPDOWN'
                      "
                    >
                      <v-layout
                        v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)"
                        v-for="(item_dropdown,
                        index_dropdown) in item_field.template_field_dropdown"
                        :key="index_dropdown"
                        row
                        class="px-6"
                        justify-center
                      >
                        <v-flex
                          xl3
                          lg3
                          md3
                          sm3
                          xs4
                          pb-n2
                          :style="style_justify_align_center"
                        >
                          <v-chip small class="mt-n4 px-2"
                            ><span class="font-weight-bold">{{
                              ["xs"].includes($vuetify.breakpoint.name)
                                ? `Value`
                                : $t(
                                    "smartinput.template_form.field_dropdown"
                                  ) +
                                  (index_dropdown + 1)
                            }}</span></v-chip
                          >
                        </v-flex>
                        <v-flex xl7 lg6 md4 sm4 xs7>
                          <v-text-field
                            :disabled="type_manage === 'V'"
                            outlined
                            solo
                            flat
                            dense
                            color="#174966"
                            class="pt-2 ml-n1"
                            v-model.trim="item_dropdown.dropdown_value"
                            :error-messages="
                              template_dropdown_field_errormsg(
                                index_field,
                                index_dropdown
                              )
                            "
                            @blur="
                              $v.array_form_template.$each[
                                index_field
                              ].template_field_dropdown.$each[
                                index_dropdown
                              ].dropdown_value.$touch()
                            "
                          >
                          </v-text-field>
                        </v-flex>
                        <v-flex
                          v-if="type_manage !== 'V'"
                          xl2
                          lg3
                          md2
                          sm2
                          xs1
                          class="pl-xs-1 pl-2"
                          style="display: flex; justify-content: start;"
                        >
                          <v-btn
                            v-if="
                              index_dropdown ===
                                item_field.template_field_dropdown.length - 1
                            "
                            fab
                            x-small
                            color="mt-3"
                            depressed
                            @click="
                              fn_increase_dropdown(index_field, index_dropdown)
                            "
                          >
                            <v-icon>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                          <v-btn
                            v-if="index_dropdown + 1 > 2"
                            fab
                            x-small
                            color="error"
                            class="mt-3 ml-3"
                            depressed
                            @click="
                              fn_decrease_dropdown(index_field, index_dropdown)
                            "
                          >
                            <v-icon>
                              mdi-trash-can
                            </v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                      <v-layout v-else column>
                        <v-layout>
                          <v-flex xs4 sm4 :style="style_justify_align_center">
                            <v-chip
                              small
                              class="mt-n4 px-2"
                              style="position: sticky;"
                              ><span class="font-weight-bold">{{
                                $t(
                                  "smartinput.template_form.field_dropdown_mobile"
                                ) +
                                  (index_dropdown + 1)
                              }}</span></v-chip
                            >
                          </v-flex>
                          <v-flex xs8 sm6>
                            <v-text-field
                              :disabled="type_manage === 'V'"
                              outlined
                              solo
                              flat
                              dense
                              color="#174966"
                              class="pt-2"
                              v-model.trim="item_dropdown.dropdown_value"
                              :error-messages="
                                template_dropdown_field_errormsg(
                                  index_field,
                                  index_dropdown
                                )
                              "
                              @blur="
                                $v.array_form_template.$each[
                                  index_field
                                ].template_field_dropdown.$each[
                                  index_dropdown
                                ].dropdown_value.$touch()
                              "
                            >
                            </v-text-field>
                          </v-flex>
                          <v-flex
                            sm2
                            v-if="
                              type_manage !== 'V' &&
                                ['sm'].includes($vuetify.breakpoint.name)
                            "
                            pt-n3
                            class="pl-3"
                            :style="style_align_center"
                          >
                            <v-btn
                              v-if="
                                index_dropdown ===
                                  item_field.template_field_dropdown.length - 1
                              "
                              small
                              icon
                              class="mt-n5"
                              @click="
                                fn_increase_dropdown(
                                  index_field,
                                  index_dropdown
                                )
                              "
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                              color="error"
                              v-if="index_dropdown + 1 > 2"
                              small
                              icon
                              class="mt-n5"
                              @click="
                                fn_decrease_dropdown(
                                  index_field,
                                  index_dropdown
                                )
                              "
                            >
                              <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                          </v-flex>
                          <v-flex
                            xs1
                            v-if="
                              type_manage !== 'V' &&
                                ['xs'].includes($vuetify.breakpoint.name)
                            "
                            pt-n3
                            class="pl-3"
                            :style="style_align_center"
                          >
                            <v-btn
                              color="error"
                              v-if="index_dropdown + 1 > 2"
                              small
                              icon
                              class="mt-n5 ml-n1"
                              @click="
                                fn_decrease_dropdown(
                                  index_field,
                                  index_dropdown
                                )
                              "
                            >
                              <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-layout
                          justify-center
                          class="pt-4"
                          v-if="
                            index_dropdown ===
                              item_field.template_field_dropdown.length - 1 &&
                              type_manage !== 'V' &&
                              ['xs'].includes($vuetify.breakpoint.name)
                          "
                        >
                          <v-btn
                            small
                            icon
                            class="mt-n3"
                            @click="fn_increase_dropdown(index_field, 1)"
                          >
                            <v-chip small outlined>
                              <v-icon>mdi-plus</v-icon>
                            </v-chip>
                          </v-btn>
                        </v-layout>
                      </v-layout>
                    </div> -->
                    <!-- <v-flex
                      xs12
                      :style="
                        `display: flex; align-items: center; justify-content: ${
                          type_manage === 'V' ? 'end' : 'start'
                        };`
                      "
                    >
                      <div :style="style_required_mobile" class="mt-4">
                        <v-checkbox
                          hide-details
                          class="mt-n1 ml-3"
                          v-model="item_field.template_field_required"
                          v-if="type_manage !== 'V'"
                        >
                        </v-checkbox>
                        <v-chip
                          small
                          v-if="
                            type_manage !== 'V' ||
                              (type_manage === 'V' &&
                                item_field.template_field_required)
                          "
                        >
                          <span class="font-weight-bold">{{
                            $t("smartinput.template_form.field_reuqired")
                          }}</span>
                        </v-chip>
                      </div>
                    </v-flex> -->
                    <!-- <v-flex xl12 class="mt-5" v-if="type_manage !== 'V'">
                      <v-btn
                        v-if="
                          index_field !== 0 || array_form_template.length > 1
                        "
                        block
                        color="error"
                        @click="fn_remove_template_field(index_field)"
                      >
                        {{ $t("smartinput.btn_delete_field") }}
                      </v-btn>
                    </v-flex> -->
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-flex
              xs12
              v-if="(type_manage === 'SC' && isComplete_Field ) || (type_manage === 'ED' && isComplete_Field) "
              class="mt-2 py-1"
              style="display: flex; justify-content: center; border-radius: 5px;"
            >
              <v-btn :x-small="['sm', 'xs'].includes($vuetify.breakpoint.name)" :small="['md', 'lg', 'xl'].includes($vuetify.breakpoint.name)" fab depressed @click="fn_increase_field_template()">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions></v-card-actions>
        <v-card-actions v-if="!isloading_expands" style="max-height: 40%; overflow-y: auto; display: flow;" class="pa-2 content-field">
          <div
            class="pa-1 px-3"
            :style="`background-color: ${$vuetify.theme.dark ? '#333333' : '#fafafa'}; width: 100%; border-radius: 10px; overflow-y:auto;`"
          >
            <!-- <v-icon>mdi-heart</v-icon> -->
            <div v-for="(item_struct, index_struct) in array_form_template" :key="index_struct">
              <span class="text-body-2" style="font-size: 14px;">{{
              struct_by_group(item_struct)
            }}</span>  <br />
            </div>
          </div>
        </v-card-actions>
        <v-card-actions>
          <!-- <div style="max-width: 500px;" v-if="['md', 'lg', 'xl'].includes($vuetify.breakpoint.name)">
                      <span v-if="type_manage === 'SC'" >{{ structure_test }}</span>
                  </div> -->
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            size="20"
            width="3"
          />
          <v-spacer></v-spacer>
          <v-btn @click="fn_close_template()" color="red" outlined>{{
            $t("smartinput.btn_cancel")
          }}</v-btn>
          <v-btn
            class="white--text"
            :color="color.theme"
            v-if="type_manage !== 'V'"
            :disabled="
              type_show_template === 'ED'
                ? checktemplatefolder === true 
                  ? patholdfolderdata.path !== datafoldertemplate.path
                    ? datafoldertemplate.path === undefined 
                      ? true 
                      : false
                    : datafoldertemplate.path === undefined 
                      ? true 
                      : detect_changed_template(template_changed)  
                      ? false 
                      : true
                  : patholdfolderdata.path !== datafoldertemplate.path 
                    ? false : detect_changed_template(template_changed)
                    ? false
                    : true
                : isLoading
                ? true
                : false
            "
            @click="fn_manage_template(type_manage)"
          >
          <!-- :dark="
              type_show_template === 'ED'
                ? patholdfolderdata.path !== datafoldertemplate.path ? true : detect_changed_template(template_changed)
                  ? true
                  : false
                : type_show_template === 'SC'
                ? isLoading
                  ? false
                  : true
                : false
            " -->
            {{
              type_show_template === "SC"
                ? $t("smartinput.btn_save")
                : $t("smartinput.btn_save_change")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogselectmainfoldertemplate
      :show="openselectmainfoldertemplate"
      :newfolder="datafoldercreate"
      @closenoreload="openselectmainfoldertemplate = false"
      @createfolder="preparingCreateFolder"
      @submitfoldertemplate="addFolderTemplate"
    ></dialogselectmainfoldertemplate>
    <dialogcreatefoldertemplate
      :show="opencreatefoldertemplate"
      @closenoreload="opencreatefoldertemplate = false"
      @createsuccess="preparingNewFolder"
      :parentfolder="foldercreateid"
    ></dialogcreatefoldertemplate>
  </v-row>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios, { CancelToken } from "axios";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { validationMixin } from "vuelidate";
import { required, helpers, maxLength } from "vuelidate/lib/validators";
import { mapState, mapGetters, mapActions } from "vuex";
import checkCharacter from "@/globalFunctions/checkCharacter";
//const alphaNumAndDotValidator = helpers.regex("alphaNumAndDot", /^[^{}|/'"!@#$%^&*()+=?><]*$/i);
const dialogselectmainfoldertemplate = () =>
  import("@/components/optional/dialog-selectmainfoldertemplate.vue");
const dialogcreatefoldertemplate = () =>
  import("@/components/upload/dialog-createfoldertemplate.vue");

const alphaNumAndDotValidator = helpers.regex(
  "alphaNumAndDot",
  checkCharacter.checkSpecialCharacterForFoldername()
);
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  components: { 
    dialogselectmainfoldertemplate,
    dialogcreatefoldertemplate 
  },
  validations: {
    create_name_template: {
      required,
      alphaNumAndDotValidator,
      maxLength: maxLength(50),
    },
    array_form_template: {
      $each: {
        template_field_key: {
          required,
          alphaNumAndDotValidator,
          maxLength: maxLength(50),
        },
        template_field_symbol: {
          required,
          alphaNumAndDotValidator,
        },
        template_field_name: {
          required,
          alphaNumAndDotValidator,
          maxLength: maxLength(50),
        },
        template_field_dropdown: {
          $each: {
            dropdown_value: {
              required,
              alphaNumAndDotValidator,
              maxLength: maxLength(100),
            },
          },
        },
      },
    },
  },
  props: [
    "show_dialog_template",
    "template_field_infor",
    "type_show_template",
    "backup_mockup",
  ],
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    //   Error msg create name
    template_name_errormsg() {
      const errors = [];
      console.log("this.$v.create_name_template", this.$v.create_name_template);
      if (!this.$v.create_name_template.$dirty) {
        return errors;
      }
      if (
        !this.$v.create_name_template.required ||
        this.$v.create_name_template.$error
      ) {
        errors.push(this.$t("smartinput.error_message_field_name"));
      }
      return errors;
    },
    // Error msg template_field_key
    template_key_field_errormsg() {
      return (indexfield) => {
        const errors = [];
        if (
          !this.$v.array_form_template.$each[indexfield].template_field_key
            .$dirty
        ) {
          return;
        }
        if (
          !this.$v.array_form_template.$each[indexfield].template_field_key
            .required ||
          this.$v.array_form_template.$each[indexfield].template_field_key
            .$error
        ) {
          errors.push(this.$t("smartinput.error_message_field_key"));
        }
        if (
          this.dup_key ===
          this.$v.array_form_template.$each[
            indexfield
          ].template_field_key.$model.toLowerCase()
        ) {
          errors.push(this.$t("smartinput.error_message_dup"));
        }
        return errors;
      };
    },
    // Error msg template_field_name
    template_name_field_errormsg() {
      return (indexfield) => {
        const errors = [];
        if (
          !this.$v.array_form_template.$each[indexfield].template_field_name
            .$dirty
        ) {
          return;
        }
        if (
          !this.$v.array_form_template.$each[indexfield].template_field_name
            .required ||
          this.$v.array_form_template.$each[indexfield].template_field_name
            .$error
        ) {
          errors.push(this.$t("smartinput.error_message_field_labelname"));
        }
        return errors;
      };
    },
    // Error msg template_field_symbol
    template_symbol_field_errormsg() {
      return (indexfield) => {
        const errors = [];
        if (
          !this.$v.array_form_template.$each[indexfield].template_field_symbol
            .$dirty
        ) {
          return;
        }
        if (
          !this.$v.array_form_template.$each[indexfield].template_field_symbol
            .required ||
          this.$v.array_form_template.$each[indexfield].template_field_symbol
            .$error
        ) {
          errors.push(this.$t("smartinput.error_message_field_symbol"));
        }
        return errors;
      };
    },
    template_dropdown_field_errormsg() {
      return (indexfield, index_dropdown) => {
        const errors = [];
        if (
          !this.$v.array_form_template.$each[indexfield].template_field_dropdown
            .$each[index_dropdown].dropdown_value.$dirty
        ) {
          return;
        }
        if (
          !this.$v.array_form_template.$each[indexfield].template_field_dropdown
            .$each[index_dropdown].dropdown_value.required ||
          this.$v.array_form_template.$each[indexfield].template_field_dropdown
            .$each[index_dropdown].dropdown_value.$error
        ) {
          errors.push(this.$t("smartinput.error_message_field_dropdown"));
        }
        return errors;
      };
    },
    structure_test() {
      if (this.array_form_template.length <= 0) {
        return "";
      } else {
        if (this.checktemplatefolder === true) {
          let foldertemplate = "";
          if (this.datafoldertemplate.path !== undefined) {
            let path = this.datafoldertemplate.path.split("/");
            foldertemplate = path.join("/");
          }
          return `${foldertemplate}${this.create_name_template}`;
        } else {
          return `${this.create_name_template}`;
        }
      }
    },
    detect_changed_template() {
      return (array_detect) => {
        return array_detect.some((items) => items === true);
      };
    },
    //   key_field_error(index) {
    //   const errors = [];
    //   if(!this.$v.array_form_template[index].template_field_key.$dirty || this.$v.array_form_template[index].template_field_key.required){
    //     errors.push("เอาลูกเอ้าาา")
    //   }
    //   return errors
    // },
    style_font_label() {
      return `font-size: ${
        this.windowWidth <= 400
          ? "13px"
          : this.windowWidth <= 450
          ? "14px"
          : "16px"
      };`;
    },
    // style_required() {
    //   return `width: 140px; height: 50px; display: flex; 
    //               justify-content: end; align-items: center; 
    //               position: absolute;`;
    // },
    style_required () {
      return (indexField) => {
        if(indexField !== 0 || this.array_form_template.length > 1) {
            return `width: 140px; height: 50px; display: flex; 
                  justify-content: end; align-items: center; 
                  position: absolute; right: 14%;`;
        } else {
            return `width: 140px; height: 50px; display: flex; 
                  justify-content: end; align-items: center; 
                  position: absolute; right: 8%;`;
        }
      }
    },
    style_required_mobile() {
      return `display: flex; flex-direction: row; justify-content: center; align-items: center;`;
    },
    style_remove () {
      return `display: flex; flex-direction: row; 
      justify-content: center; align-items: center; position: absolute; right: 2%;`
    },
    style_justify_align_center() {
      return `display: flex;
                  justify-content: center; align-items: center;`;
    },
    style_align_center() {
      return `display: flex;
                  align-items: center;`;
    },
    right_position () {
      return (indexField) => {
        if(indexField !== 0 || this.array_form_template.length > 1){
          return '6%'
        } else {
          return '0%'
        }
      }
    },
    struct_by_group () {
      return (item_field) => {
        if(!this.checktemplatefolder){
          return `${this.create_name_template}${item_field.template_field_key !== "" && item_field.template_field_symbol !== "" ? `/XXXXXXXXXX/${item_field.template_field_key}${item_field.template_field_symbol}XXXXXXXXXX` : ``}`
        } else {
          let foldertemplate = "";
          if (this.datafoldertemplate.path !== undefined) {
            let path = this.datafoldertemplate.path.split("/"); //[a, b, c]
            foldertemplate = path.join("/");
          }
          console.log("Struvvvvvv ", item_field)
          return `${foldertemplate}${this.create_name_template}${item_field.template_field_key !== "" && item_field.template_field_symbol !== "" ? `/XXXXXXXXXX/${item_field.template_field_key}${item_field.template_field_symbol}XXXXXXXXXX` : ``}`
        }
          
      }
    },
  },
  filters: {
    //Add (Mai)
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 50) return string.substring(0, 50) + "...";
        else return string;
      }
    },
  },
  watch: {
    show_dialog_template(newval, old) {
      if (newval) {
        this.structure_template = "";
        this.array_form_template = [];
        console.log(this.type_show_template);
        if (["ED", "V"].includes(this.type_show_template)) {
          this.create_name_template = this.template_field_infor.create_name_template;
          this.backup_create_template_name = this.create_name_template;
          this.fn_fetch_detail_template(this.template_field_infor.template_id);
        } else {
          this.array_form_template = [this.default_array_form_template];
          this.panels_open = 0;
          this.create_name_template = "";
        }
        this.type_manage = this.type_show_template;
      }
      // console.log("Default ", this.$v.array_form_template )
    },
    array_form_template: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.type_manage === "SC") {
          try{
            newVal.forEach((items) => {
              // if(items.template_field_type.items_value === 'DROPDOWN'){
              //   if(items.template_field_key === "" || items.template_field_name === "") {
              //     throw 'break'
              //   } else {
              //     let check_map_dropdown = items.template_field_dropdown.map((itemsDropdown) => itemsDropdown.dropdown_value)
              //     if(check_map_dropdown.filter((itemFilter) => itemFilter === "").length >= 1){
              //       throw 'break'
              //     } else {
              //       this.isComplete_Field = true
              //     }
              //   }
                
              // } else {
              //   if(items.template_field_key === "" || items.template_field_name === "") {
              //     throw 'break'
              //   } else {
              //     this.isComplete_Field = true
              //   }
              // }
              if(items.template_field_key === "" || items.template_field_name === "") {
                  throw 'break'
                } else {
                  this.isComplete_Field = true
                }
            })
          } catch (err) {
            console.log("throw  error something null")
            this.isComplete_Field = false
          }
        } else {
          try{
            newVal.forEach((items) => {
              // if(items.template_field_type.items_value === 'DROPDOWN'){
              //   if(items.template_field_key === "" || items.template_field_name === "") {
              //     throw 'break'
              //   } else {
              //     let check_map_dropdown = items.template_field_dropdown.map((itemsDropdown) => itemsDropdown.dropdown_value)
              //     if(check_map_dropdown.filter((itemFilter) => itemFilter === "").length >= 1){
              //       throw 'break'
              //     } else {
              //       this.isComplete_Field = true
              //     }
              //   }
                
              // } else {
              //   if(items.template_field_key === "" || items.template_field_name === "") {
              //     throw 'break'
              //   } else {
              //     this.isComplete_Field = true
              //   }
              // }
              if(items.template_field_key === "" || items.template_field_name === "" || items.template_field_symbol === "") {
                  throw 'break'
                } else {
                  this.isComplete_Field = true
                }
            })
          } catch (err) {
            console.log("throw  error something null")
            this.isComplete_Field = false
          }
          if (oldVal.length !== 0) {
            if (oldVal.length > 0 && newVal.length === 0) {
              //กรณีที่มีการเปิด dialog แล้วปิดเลยไม่มีการแก้ไขอะไรอ่ะ
              console.log("ไม่ได้มีการแก้ไขอะไรเลย");
              this.template_changed = [false, false];
              return;
            } else {
              let as_string_array_form =
                newVal.length === 0 ? newVal : JSON.stringify(newVal);
              console.log("มีการแก้ไขบางอย่าง ");
              if (as_string_array_form !== this.backup_fields_template || this.checktemplatefolder !== this.backupcreatefolder) {
                this.template_changed[1] = true;
              } else {
                this.template_changed[1] = false;
              }
            }
          } else {
            console.log("E");
          }
        }
      },
    },
    create_name_template(newVal, oldVal) {
      if (oldVal !== "") {
        if (this.create_name_template !== this.backup_create_template_name) {
          this.template_changed[0] = true;
        } else {
          this.template_changed[0] = false;
        }
      }
    },
    checktemplatefolder (newVal, oldVal) {
      if(this.type_show_template === 'ED'){
        this.datafoldertemplate = !newVal ? {} : this.patholdfolderdata
        console.log("patholdfolderdata ", this.patholdfolderdata)
        console.log("datafoldertemplate ", this.datafoldertemplate)
      }
      
    }
  },
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      // DATA MOCKUP
      isloading_expand: true,
      isLoading: false,
      text_text_mockup: "",
      type_manage: "SC",
      windowWidth: window.innerWidth,
      panels_open: null,
      array_create_field: [0],
      array_create_dropdown: [0],
      array_recent_pointer: [{ index: 0, pointer: 0 }],
      count_field_template: 3,
      formUsername: "",
      isSomethingChanged: false,
      template_select: { items_id: 1, items_text: "Text", items_value: "text" },
      test_create_: "",
      create_name_template: "",
      default_field_dropdown: [
        {
          dropdown_key: 1,
          dropdown_value: "",
        },
        {
          dropdown_key: 2,
          dropdown_value: "",
        },
      ],
      backup_array_form_template: [],
      backup_template_fields: [],
      // /////////////////////////

      // DATA ACTUALLY
      isComplete_Field: false,
      backupcreatefolder: false,
      dup_key: [],
      source_cancelToken: "",
      isloading_expands: false,
      header_structure_template: "",
      ui_mockup: false,
      structure_template: "",
      array_form_template: [],
      backup_fields_template: [],
      template_changed: [false, false], //[ชื่อ, field_template]
      backup_create_template_name: "",
      items_type_template: [
        {
          items_id: 1,
          items_text: "smartinput.select_default.item_text",
          items_value: "TEXT",
        },
        {
          items_id: 2,
          items_text: "smartinput.select_default.item_date",
          items_value: "DATE",
        },
        {
          items_id: 3,
          items_text: "smartinput.select_default.item_dropdown",
          items_value: "DROPDOWN",
          // items_dropdown: [{dropdown_key: "DD1", dropdown_value: ""}]
        },
      ],
      default_array_form_template: {
        template_field_key: "",
        template_field_name: "",
        template_field_symbol: "",
      //   template_field_type: {
      //     items_id: 1,
      //     items_text: "smartinput.select_default.item_text",
      //     items_value: "TEXT",
      //   },
      //   template_field_dropdown: [
      //     {
      //       dropdown_key: 1,
      //       dropdown_value: "",
      //     },
      //     {
      //       dropdown_key: 2,
      //       dropdown_value: "",
      //     },
      //   ],
        template_field_required: false,
        template_field_active: true,
      },
      checktemplatefolder: false,
      openselectmainfoldertemplate: false,
      opencreatefoldertemplate: false,
      datafoldercreate: "",
      foldercreateid: "",
      datafoldertemplate: {},
      patholdfolderdata: {}
    };
  },
  methods: {
      fn_scrollToBottom () {
        try {
          setTimeout(() => {
          let children_lenfth = document.getElementsByClassName(
            "v-expansion-panels"
          )[0].children;
          document
            .getElementsByClassName("v-expansion-panels")[0]
            .childNodes[children_lenfth.length - 1].scrollIntoView({
              block: "start",
              inline: "nearest",
              behavior: "smooth",
            });
        }, 300);
          } catch (err) {
            console.log("กันเหนียว");
          }
      },
      fn_keypresssSymbol (event, indexField) {
          try{
              event = (event) ? event : window.event;
              let expect = event.target.value.toString() + event.key.toString();
              if (this.array_form_template[indexField].template_field_symbol.length === 1) {
                  event.preventDefault();
              } else {
                  return true;
              }
          } catch (err) {
              console.log("error length")
          }
          
      },
    addFolderTemplate(data) {
      this.datafoldertemplate = data;
    },
    preparingNewFolder(item) {
      this.datafoldercreate = item;
    },
    preparingCreateFolder(id) {
      this.foldercreateid = id;
      this.opencreatefoldertemplate = true;
    },
    fn_disabled_checkbox (index_field) {
      let disabled_checkbox = this.array_form_template.map((item) => item.template_field_active)
      let filtered_checkbox = disabled_checkbox.filter((itemBool) => itemBool === true)
      if(filtered_checkbox.length === 1){
        return disabled_checkbox[index_field]
      }
    },
    // METHOD APPLICATION
    fn_clear_template() {
      // ต้องทำการ reset หทด
      this.$v.create_name_template.$reset();
      this.$v.array_form_template.$reset();
      this.default_array_form_template = {
        template_field_key: "",
        template_field_name: "",
        template_field_symbol: "",
      //   template_field_type: {
      //     items_id: 1,
      //     items_text: "smartinput.select_default.item_text",
      //     items_value: "TEXT",
      //   },
      //   template_field_dropdown: [
      //     {
      //       dropdown_key: 1,
      //       dropdown_value: "",
      //     },
      //     {
      //       dropdown_key: 2,
      //       dropdown_value: "",
      //     },
      //   ],
        template_field_required: false,
        template_field_active: true,
      };
      this.dup_key = [];
      this.backup_fields_template = [];
      this.array_form_template = [];
      this.template_changed = [false, false];
      this.create_name_template = "";
      this.backup_create_template_name = "";
      this.header_structure_template = "";
      this.panels_open = null;
      this.isLoading = false;
      this.isloading_expands = false;
      this.isSomethingChanged = false;
      this.checktemplatefolder = false;
      this.datafoldertemplate = {};
    },
    fn_close_loading_template() {
      this.fn_clear_template();
      this.$emit("close_dialog", "CLOSE_LOADING");
    },
    fn_close_template() {
      this.fn_clear_template();
      try {
        this.source_cancelToken.cancel();
      } catch (err) {
        console.log("error sourceToken but it's ok ");
      }

      this.$emit("close_dialog", "CLOSE");
    },
    fn_increase_dropdown(index_field, index_dropdown) {
      let recent_dropdown_index = this.array_form_template[index_field][
        "template_field_dropdown"
      ]
        .map((itemMap) => itemMap.dropdown_key)
        .slice(-1)[0];
      this.array_form_template[index_field]["template_field_dropdown"].push({
        dropdown_key: recent_dropdown_index + 1,
        dropdown_value: "",
      });
      console.log("Check Data here ", this.array_form_template);
    },
    fn_decrease_dropdown(index_field, index_dropdown) {
      this.array_form_template[index_field]["template_field_dropdown"].splice(
        index_dropdown,
        1
      );
    },
    fn_increase_field_template() {
      this.array_form_template.push({
        template_field_key: "",
        template_field_name: "",
        template_field_symbol: "",
      //   template_field_type: {
      //     items_id: 1,
      //     items_text: this.$t("smartinput.select_default.item_text"),
      //     items_value: "TEXT",
      //   },
      //   template_field_dropdown: [
      //     {
      //       dropdown_key: 1,
      //       dropdown_value: "",
      //     },
      //     {
      //       dropdown_key: 2,
      //       dropdown_value: "",
      //     },
      //   ],
        template_field_required: false,
        template_field_active: true,
      });
      this.panels_open = this.array_form_template.length - 1;
      this.fn_scrollToBottom()

      //console.log("Class", document.getElementsByClassName('v-expansion-panels')[0].children[children_lenfth.length - 1]);
    },
    fn_remove_template_field(index) {
      this.array_form_template.splice(index, 1);
      if(this.array_form_template.map((item) => item.template_field_active).filter((Boole) => Boole === true).length <= 0){
        this.array_form_template[0].template_field_active = true
      }
      
    },
    fn_manage_template(type) {
      // SAVE/CREATE, EDIT, DELETE
      if (type === "SC") {
        // SAVE CREATE
        // เช็คชื่อ Name template ก่อนว่า Error รึป่าว
        if (
          this.create_name_template === "" ||
          this.$v.create_name_template.$error
        ) {
          this.$v.create_name_template.$touch();
          return;
        }
        if (this.array_form_template.length === 0) {
          return;
        }
        // ต้องทำการตรวจสอบก่อนว่า field ตัวไหนที่ยังเป็นค่าว่างอยู่บ้าง ด้วยวิธีการดังนี้
        this.fn_check_complet_fields().then((response) => {
          // ต้องทำการเช็ค template_field_key ก่อนว่าแต่ละอันมันซ้ำกันมั้ย
          var all_field_key_name = this.array_form_template.map((itemMap) =>
            itemMap.template_field_key.toLowerCase()
          );
          let duplicate_field_key = [
            ...new Set(
              all_field_key_name.filter((item, index) =>
                all_field_key_name.some(
                  (elem, idx) => elem === item && idx !== index
                )
              )
            ),
          ];
          if (duplicate_field_key.length > 0) {
            let manage_index = duplicate_field_key.map((item) =>
              all_field_key_name
                .map((items, index) => {
                  if (item === items) return index;
                })
                .filter((itemValue) => itemValue !== undefined)
            );
            console.log("เอา Index ทุกตำแหน่งของคำที่ซ้ำกัน ", manage_index);
            let sort_number = manage_index
              .map((items) => items[1])
              .sort((a, b) => {
                return a - b;
              });
            console.log(
              "เอา Index ตัวแรกของทุกกลุ่มมา เรียงจากน้อยไปมาก ",
              sort_number
            );
            this.panels_open = sort_number[0];
            this.dup_key = all_field_key_name[this.panels_open];
            return;
          } else {
            console.log("เรียก API ");
            this.isLoading = true;
            if (response === "COMPLETLY CHECKING") {
              console.log("ALL data ", this.array_form_template);
              this.fn_create_template({
                template_create_name: this.create_name_template,
                template_fields: this.array_form_template,
                template_structure: this.structure_test
                  .split("/")
                  // .map((wordMap) => wordMap.trim())
                  .join("/"),
              });
            } else {
              return;
            }
          }
        });
      } else if (type === "ED") {
        // EDIT
        // ทำการเช็ค name template
        if (
          this.create_name_template === "" ||
          this.$v.create_name_template.$error
        ) {
          this.$v.create_name_template.$touch();
          return;
        }
        if (this.array_form_template.length === 0) {
          return;
        }
        this.fn_check_complet_fields().then((responsechecking) => {
          // ต้องทำการเช็ค template_field_key ก่อนว่าแต่ละอันมันซ้ำกันมั้ย
          var all_field_key_name = this.array_form_template.map((itemMap) =>
            itemMap.template_field_key.toLowerCase()
          );
          let duplicate_field_key = [
            ...new Set(
              all_field_key_name.filter((item, index) =>
                all_field_key_name.some(
                  (elem, idx) => elem === item && idx !== index
                )
              )
            ),
          ];
          if (duplicate_field_key.length > 0) {
            let manage_index = duplicate_field_key.map((item) =>
              all_field_key_name
                .map((items, index) => {
                  if (item === items) return index;
                })
                .filter((itemValue) => itemValue !== undefined)
            );
            let sort_number = manage_index
              .map((items) => items[1])
              .sort((a, b) => {
                return a - b;
              });
            this.panels_open = sort_number[0];
            this.dup_key = all_field_key_name[this.panels_open];
            return;
          } else {
            this.isLoading = true;
            if (responsechecking === "COMPLETLY CHECKING") {
              console.log("ALL data ", this.array_form_template);
              this.fn_update_template({
                template_create_name: this.create_name_template,
                template_fields: this.array_form_template,
                template_structure: this.structure_test
                  .split("/")
                  // .map((wordMap) => wordMap.trim())
                  .join("/"),
              });
            }
          }
        });
      }
    },
    fn_changetypefield(itemField, type, event) {
      console.log("event ", event);
      if (type === "ED") {
        if (event.items_value === "DROPDOWN") {
          // ต้องการทำการเช็คก่อนว่า backup ของ field ตัวนี้เคบเป็น dropdown มาก่อนมั้ย
          let backup_finding = JSON.parse(this.backup_fields_template);
          console.log("BACKUP STARGE ", backup_finding);
          console.log("ItemField ", itemField);
          //backup_finding = backup_finding.find((items) => items.template_field_key === itemField.template_field_key && items.template_field_name === itemField.template_field_name)
          backup_finding = backup_finding.find(
            (items) => items.template_field_id === itemField.template_field_id
          );
          // กรณีที่ 1: จะเป็นทั้งกรณีที่ยุสมีการเพิ่ม field ขึ้นมา จากเดิมของ backup หรือ กรณีที่fieldนี้ไม่ได้เป็น dropdown มาก่อน
          if (
            backup_finding === undefined ||
            backup_finding.template_field_dropdown.length === 0
          ) {
            itemField.template_field_dropdown = [
              {
                dropdown_key: 1,
                dropdown_value: "",
              },
              {
                dropdown_key: 2,
                dropdown_value: "",
              },
            ];
          }
          // กรณีที่ 2 : ถ้าหาก field นี้เคยเป็น dropdown มาก่อน ก็จะทำการเอาค่า dropdwon ที่อยู่ใน backup มาใส่ให้เหมือนเดิม
          else if (backup_finding.template_field_dropdown.length > 0) {
            itemField.template_field_dropdown =
              backup_finding.template_field_dropdown;
          }
        } else {
          itemField.template_field_dropdown = [];
        }
      } else {
        if (event.items_value === "DROPDOWN") {
          // ถ้าอยากจะเพิ่มให้มันเคลียร์ค่า
        }
      }
    },
    fn_detect_focus_fieldKey(index_field) {
      this.$v.array_form_template.$each[
        index_field
      ].template_field_key.$touch();
    },
    fn_detect_focus(event) {
      this.$v.create_name_template.$touch();
      console.log(this.$v.create_name_template);
      this.header_structure_template = `${this.create_name_template}/`;
    },
    fn_check_complet_fields() {
      return new Promise((resolve, reject) => {
        //  หา array ของ field_template ของ validator จะได้เป็น array field ของแต่ละตัว
        let find_key_template = Object.keys(
          this.$v.array_form_template.$each
        ).slice(0, this.array_form_template.length);
        // สร้างตัวแปรในการเก็บวสถานะสำเร็จหรือไม่สำเร็จของ field แต่ละตัว
        var check_status_invalid_required = [];
        // เอา array field ของแต่ละตัวมาตรวจสอบว่า template_field_key และ template_field_name มีค่าว่างหรือไม่
        find_key_template.forEach((itemsIndex) => {
          // จะกรองแค่ objecy key ที่เป็น template_field_key และ template_field_name เท่านั้นจากใน $each ของ validator และเอา object นั้นๆ มาทำเป็น array
          const ArrayFiltered_object = Object.entries(
            this.$v.array_form_template.$each[itemsIndex]
          ).filter(
            ([key, value]) =>
              key === "template_field_key" || key === "template_field_name" || key === "template_field_symbol"
          );
          var value_dropdown = [];
          // ถ้าหาก field นั้นมีการเลือกแบบ dropdown ไว้ จะเข้า COND นี้
          // if (
          //   this.array_form_template[itemsIndex].template_field_type
          //     .items_value === "DROPDOWN"
          // ) {
          //   this.array_form_template[
          //     itemsIndex
          //   ].template_field_dropdown.forEach((itemDropDown, indexDropDown) => {
          //     let entries_dropdown = Object.entries(
          //       this.$v.array_form_template.$each[itemsIndex]
          //         .template_field_dropdown.$each[indexDropDown]
          //     ).filter(([key, value]) => key === "dropdown_value");
          //     entries_dropdown.forEach((itemWrap) => {
          //       itemWrap[itemWrap.length - 1] = Object.values(
          //         itemWrap[itemWrap.length - 1]
          //       )
          //         .slice(0, 3)
          //         .every((typeBoolean) => typeBoolean === true);
          //     });
          //     value_dropdown.push(entries_dropdown[0][1]);
          //   });
          // }
          // /////////////////////////////////////////////////

          // loop เอาค่่าของ $invalid มาใส่เป็น array
          ArrayFiltered_object.forEach((itemBeforeArray) => {
            // เอาค่าแค้ value ใน object มาใสห่่ [['template', {value: true}], ['template', {value: false}], ['template_dropdown', {value: true}, {value: true}]] => [['template', true], ['template', false]]
            //itemBeforeArray[itemBeforeArray.length-1] = Object.values(itemBeforeArray[itemBeforeArray.length-1])[0]
            itemBeforeArray[itemBeforeArray.length - 1] = Object.values(
              itemBeforeArray[itemBeforeArray.length - 1]
            )
              .slice(0, itemBeforeArray[0] === 'template_field_symbol' ? 2 : 3)
              .every((typeBoolean) => typeBoolean === true);
          });

          console.log("Array Filter ", ArrayFiltered_object)
          // แปลงจ่าค่า array ให้กลับมาเป็น object เหมือนเดิม [['template', true], ['template', false]] => [{template:true},{ template:false}, {template_dropwdown: [true, true, true]}]
          let object_checking_ = Object.fromEntries(ArrayFiltered_object);
          // // สร้าง template_field_dropdown ไว้เช็ค
          // object_checking_["template_field_dropdown"] =
          //   this.array_form_template[itemsIndex].template_field_type
          //     .items_value === "DROPDOWN"
          //     ? value_dropdown
          //     : [];
          object_checking_["template_field_value"] = this.array_form_template[
            itemsIndex
          ];
          // // เช็คว่าแต่ละ template_field สำเร็จหรือไม่ และจะเพิ่่ม status ของ field นั้น status = true (tempalte_field_key = true และ template_field_name = true)
          // if (
          //   this.array_form_template[itemsIndex].template_field_type
          //     .items_value === "DROPDOWN"
          // ) {
          //   let check_field_main = [
          //     object_checking_.template_field_key,
          //     object_checking_.template_field_name,
          //   ];
          //   if (check_field_main.every((element) => element === true)) {
          //     //แสดงว่า field_key และ field_name ผ่าน
          //     if (
          //       object_checking_.template_field_dropdown.every(
          //         (element) => element === true
          //       )
          //     ) {
          //       // แสดงว่า dropdown_value ผ่าน (หมายถึง มึค่าใส่ทุกช่อง)
          //       object_checking_["status_complate_input_field"] = true;
          //     } else {
          //       object_checking_["status_complate_input_field"] = false;
          //     }
          //   } else {
          //     object_checking_["status_complate_input_field"] = false;
          //   }
          // } else {
          //   object_checking_["status_complate_input_field"] =
          //     object_checking_.template_field_key === true &&
          //     object_checking_.template_field_name === true &&
          //     object_checking_.template_field_symbol === true
          //       ? true
          //       : false;
          // }
          object_checking_["status_complate_input_field"] =
              object_checking_.template_field_key === true &&
              object_checking_.template_field_name === true &&
              object_checking_.template_field_symbol === true
                ? true
                : false;

          // เก็บไว้ในตัว checking
          check_status_invalid_required.push(object_checking_);
          // [{template_field_key: true, template_field_name: true, template_dropdown: [true, true, true], status_complate_input_field: true}]
        });
        console.log("checkkkk rid ", check_status_invalid_required)
        // เอา object มาเป็น array เพื่อหาตัว array ที่ไม่สำเร็จ
        let open_panels_expand_by_index = check_status_invalid_required.map(
          (itemsMap) => itemsMap.status_complate_input_field
        );
        this.panels_open = open_panels_expand_by_index.findIndex(
          (itemValueCheck) => !itemValueCheck
        );
        // Touch error ต่ำแหน่งที่ไม่สำเร็จ
        // ใส่ try ไว้กรณี panel เป็น -1 แสดงว่าไม่มีตัวไหนที่ไม่สำเร็จ
        try {
          if (
            !check_status_invalid_required[this.panels_open].template_field_key
          ) {
            this.$v.array_form_template.$each[
              this.panels_open
            ].template_field_key.$touch();
          } else if (
              !check_status_invalid_required[this.panels_open].template_field_symbol
          ) {
              this.$v.array_form_template.$each[
              this.panels_open
            ].template_field_symbol.$touch();
          } else if (
            !check_status_invalid_required[this.panels_open].template_field_name
          ) {
            this.$v.array_form_template.$each[
              this.panels_open
            ].template_field_name.$touch();
          }  else if (
            !check_status_invalid_required[
              this.panels_open
            ].template_field_dropdown.every(
              (typeBoolean) => typeBoolean === true
            )
          ) {
            let findIndexError = check_status_invalid_required[
              this.panels_open
            ].template_field_dropdown.findIndex(
              (itemBoolean) => itemBoolean === false
            );
            this.$v.array_form_template.$each[
              this.panels_open
            ].template_field_dropdown.$each[findIndexError].$touch();
          }
          console.log("ทดสอบบ");
          return;
        } catch (err) {
          console.log("ALL Field are success");
          resolve("COMPLETLY CHECKING");
        }
      });
    },
    // METHOD AXIOS WITH API
    async fn_fetch_detail_template(template_id) {
      this.isloading_expands = true;
      this.source_cancelToken = CancelToken.source();
      const cancelToken_source = this.source_cancelToken.token;
      let auth = await gbfGenerate.generateToken();
      const payload = {
        template_id: template_id,
      };
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN_BOX +
            "/api/v1/smartbox/get/template/details",
          payload,
          {
            headers: { Authorization: auth.code },
            cancelToken: cancelToken_source,
          }
        )
        .then((response) => {
          console.log("REPOSNE ", response.data.data);
          let checkfoldertemplate = response.data.data.structure_data.split("/");
          let countduptemplatename = checkfoldertemplate.filter(item => item === response.data.data.template_name).length;
          this.checktemplatefolder = countduptemplatename > 1 ? true : checkfoldertemplate[0] !== response.data.data.template_name ? true : false;
          let path = ""
          for (let i = 0; i < checkfoldertemplate.length; i++) {
            if (checkfoldertemplate[i] !== response.data.data.template_name) {
              if (checkfoldertemplate[i] !== "") {
                path += checkfoldertemplate[i] + "/"
              }
            } else {
              countduptemplatename -= 1
              if (countduptemplatename > 0) {
                if (checkfoldertemplate[i] !== "") {
                  path += checkfoldertemplate[i] + "/";
                }
              } else {
                break;
              }
            }
            if (this.checktemplatefolder === true) {
              this.datafoldertemplate = {
                path: path,
                folder_name: checkfoldertemplate[checkfoldertemplate.length - 2]
              }
              this.patholdfolderdata = {
                path: path,
                folder_name: checkfoldertemplate[checkfoldertemplate.length - 2]
              }
            }
          }
          this.structure_template = response.data.data.structure_data;
          this.array_form_template = [];
          response.data.data.aspect_data.forEach((items, index) => {
            let array_dropdown = [];
            // if (items.aspect_type === "DROPDOWN") {
            //   items.aspect_fix_value.forEach((items, index) => {
            //     let dropdown_object = {};
            //     dropdown_object["dropdown_key"] = index + 1;
            //     dropdown_object["dropdown_value"] = items;
            //     array_dropdown.push(dropdown_object);
            //   });
            // }
            let obj_create = {};
            obj_create["template_field_id"] = items.aspect_id; // แอบเพิ่มมานะจะ
            obj_create["template_field_key"] = items.aspect_name;
            obj_create["template_field_name"] = items.aspect_label;
            obj_create["template_field_symbol"] = items.aspect_symbol
            obj_create["template_field_active"] =
              items.status_value === "Y" ? true : false;
            this.array_form_template.push(obj_create);
            // ไว้ Backup field ในการcheckgเมื่อมีการแก้ไข
          });
          // STAMP BACKUP DATA AS STRINGIFY
          this.backup_fields_template = JSON.stringify(
            this.array_form_template
          );
          this.backupcreatefolder = this.checktemplatefolder
          this.isloading_expands = false;
          this.fn_scrollToBottom()
          

        })
        .catch((err) => {
          console.log("Error ", err);
        });
    },
    async fn_create_template(array_template) {
      this.isloading_expands = true
      this.source_cancelToken = CancelToken.source();
      const cancelToken_source = this.source_cancelToken.token;
      console.log(array_template.template_fields);
      var prepare_aspect_value = [];
      array_template.template_fields.forEach((items) => {
        let template_each_field = {};
        template_each_field["aspect_label"] = items.template_field_name;
        template_each_field["aspect_name"] = items.template_field_key;
        template_each_field["aspect_symbol"] = items. template_field_symbol
        template_each_field["status_value"] = items.template_field_active
          ? "Y"
          : "N";
        prepare_aspect_value.push(template_each_field);
      });
      const payload = {
        template_name: array_template.template_create_name,
        user_id: this.dataUsername,
        business_id: this.dataAccountActive.business_info.business_id,
        structure_data: this.datafoldertemplate.path === undefined ? array_template.template_create_name : array_template.template_structure,
        aspect_data: prepare_aspect_value,
      };
      console.log("Payloadd ", payload )
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN_BOX +
            "/api/v2/smartbox/create/template",
          payload,
          {
            headers: { Authorization: auth.code },
            cancelToken: cancelToken_source,
          }
        )
        .then((response) => {
          console.log("REPOSNE CREATED TEMPLATE ", response);
          if (response.data.status === "OK") {
            this.fn_close_loading_template();
            Toast.fire({
              icon: "success",
              title: this.$t("smartinput.success_create_template"),
            });
          } else {
            this.isLoading = false;
            this.isloading_expands = false;
            Toast.fire({
              icon: "error",
              title: this.$t("smartinput.error_create_template"),
            });
            console.log("มีปัญหาบางอย่าง");
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.isloading_expands = false;
          Toast.fire({
            icon: "error",
            title: this.$t("smartinput.error_some"),
          });
          console.log("มีปัญหาบางอย่าง");
        });
    },
    async fn_update_template(array_template_update) {
      this.isloading_expands = true
      var prepare_aspect_value = [];
      array_template_update.template_fields.forEach((items) => {
        let template_each_field = {};
        template_each_field["aspect_label"] = items.template_field_name;
        template_each_field["aspect_name"] = items.template_field_key;
        template_each_field["aspect_symbol"] = items.template_field_symbol
        template_each_field["status_value"] = items.template_field_active
          ? "Y"
          : "N";
        prepare_aspect_value.push(template_each_field);
      });
      const payload = {
        template_name: array_template_update.template_create_name,
        user_id: this.dataUsername,
        template_id: this.template_field_infor.template_id,
        structure_data: this.datafoldertemplate.path === undefined ? array_template_update.template_create_name : array_template_update.template_structure,
        aspect_data: prepare_aspect_value,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN_BOX +
            "/api/v2/smartbox/update/template",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            this.fn_close_loading_template();
            Toast.fire({
              icon: "success",
              title: this.$t("smartinput.success_edit_template"),
            });
          } else {
            this.isloading_expands = false
            Toast.fire({
              icon: "error",
              title: this.$t("smartinput.error_edit_template"),
            });
          }
        })
        .catch((err) => {
          Toast.fire({
            icon: "error",
            title: this.$t("smartinput.error_some"),
          });
        });
    },
    fn_get_typefield(type) {
      return this.items_type_template.filter(
        (itemValue) => itemValue.items_value === type
      )[0];
    },
    fn_change_field_key(field_key) {
      console.log("Field Key name ", field_key);
    },
  },
  created() {
    this.array_form = "";
  },
};
</script>

<style scoped>
:root {
  --right-position: green;
}
::v-deep .v-text-field input {
  /* Your custom styles for the input element go here */
  line-height: inherit;
}
::v-deep
  .v-text-field
  .v-text-field__details
  .v-messages
  .v-messages__wrapper
  .v-messages__message {
  line-height: normal;
  padding-bottom: 3px;
}

::v-deep .v-expansion-panel-header__icon{
  position: relative;
  right: var(--right-position);

}
::v-deep.content-field::-webkit-scrollbar {
  color: red;
}
</style>
